﻿(function($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget;

    var storageNamespace = 'tileConfigurationManager',
        storageTileConfigurationKey = 'tileConfiguration',
        camerasKey = 'cameras',
        baseTileLayoutClass = 'monitoringArea',
        currentLayout,
        accountId,
        LAYOUT_CHANGED = 'layout-changed';

    var configurationManager = widget.extend({
        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget).
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'TileConfigurationManager'
        },

        events: [
            LAYOUT_CHANGED
        ],

        changeTileLayout: function(newLayoutClass, fadeInCallback, fadeOutCallback) {
            var that = this;

            if (currentLayout === newLayoutClass) {
                return;
            }

            currentLayout = newLayoutClass;
            // We do not fade out to 0 to avoid silverlight objects from reloading
            that.element.fadeTo(300, 0, function() {
                fadeInCallback();

                that.restoreTiles();

                that.element.removeClass();
                that.element.addClass(baseTileLayoutClass);
                that.element.addClass(newLayoutClass);

                that.element.fadeTo(300, 1.0, fadeOutCallback);
            });
        },

        init: function(element, options) {
            var that = this;
            widget.fn.init.call(that, element, options);
            that.element = $(element);
            accountId = options.accountId;
        },

        getCurrentLayout: function() {
            return currentLayout;
        },

        getAllTiles: function() {
            return $(this.options.selectors.tileCell);
        },

        restoreSavedLayout: function(configName) {
            var that = this;

            var savedLayout = that._getSavedLayout(configName);
            if (savedLayout !== null) {
                that.element.removeClass();
                that.element.addClass(baseTileLayoutClass);
                that.element.addClass(savedLayout);
                currentLayout = savedLayout;
            }

            return savedLayout;
        },

        _getSavedLayout: function(configName) {
            var config = this._getConfig(configName);

            return !!config ? config.layout : null;
        },

        getSavedCameras: function(configName) {
            var config = this._getConfig(configName);

            return !!config ? config.cameras : null;
        },

        getVisibleTiles: function() {
            return $(this.options.selectors.tileCell + ' > :visible');
        },

        restoreTiles: function() {
            var classes = this.options.classes;

            var descendants = this.element.find('*');
            descendants.removeClass(classes.tileExpand);
            descendants.removeClass(classes.hideForTileExpand);
        },

        saveConfiguration: function(options) {
            var storage = this._initStorage();

            if (!storage) {
                return;
            }

            var cameras = options.cameras;
            var configName = options.configName;

            var config = {};
            var userConfig = this._getUserConfig() || {};
            userConfig[configName] = {
                layout: currentLayout,
                cameras: cameras
            };

            config[this._getUserAccountKey()] = userConfig;
            storage.localStorage.set(config);
        },

        _initStorage: function() {
            var storage;

            try {
                storage = $.initNamespaceStorage(storageNamespace);
            } catch (e) {
                return null;
            }

            return storage;
        },

        _getConfig: function(configName) {
            var userConfig = this._getUserConfig();

            if (!userConfig) {
                return null;
            }

            var config = userConfig[configName];
            return !!config ? config : null;
        },

        _getUserConfig: function() {
            var storage = this._initStorage();

            if (!storage) {
                return null;
            }

            var userConfig = storage.localStorage.get(this._getUserAccountKey());

            return !!userConfig ? userConfig : null;
        },

        _getUserAccountKey: function() {
            return storageTileConfigurationKey + '-user-' + accountId;
        }
    });

    ui.plugin(configurationManager);

})(jQuery);

﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget;

    var videoPlayerManager = widget.extend({

        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'VideoPlayerManager',

            // other options go here
            autoBind: true,

            slidingCookieInterval: 60000,
        },

        init: function (element, options) {

            var that = this;

            widget.fn.init.call(that, element, options);

            //The following data property does not come in the options because it was not defined in the original options object
            //It's not there because we do not have a default value for it.
            that.options.slidingCookieUrl = $(element).data('slidingCookieUrl');

            that._ifNeededBindGlobalFunctionsToWindow();

            //TODO: Enable later on
            //that._startSlidingCookiesPolling(that.options.slidingCookieInterval);
        },

        value: function (dataBoundProperties) {
            //This function is called when the widget is bound, after it's initialized, we save the values that we want
            var that = this;
            that.videoPlayerToken = dataBoundProperties.videoPlayerToken;
            that.portalApiHostName = { hostname: dataBoundProperties.portalApiHostName };
        },
        
        _getAdminPortalWebApiUrl: function () {

            console.log('Web Api Url: ' + this.portalApiHostName.hostname);

            return this.portalApiHostName;
        },

        _getSlidingCookies: function (skipSlidingCookies) {

            var that = this;

            if (that.options.slidingCookieUrl == undefined) {
                console.log('Missing slidingCookieUrl');
                return;
            }

            var customHeader = new Object();
            if (skipSlidingCookies == undefined || skipSlidingCookies) {
                customHeader = { "g-session-skip-sliding": "true" };
            }

            var currentPage = "Monitoring";

            $.ajax({
                url: that.options.slidingCookieUrl,
                accepts: "application/json",
                headers: customHeader,
                type: "post",
                data: { isMonitoring: currentPage == "Monitoring" }
            }).done(function (data) {
                that.videoPlayerToken = data.VideoPlayerToken;
            });

            that._startSlidingCookiesPolling(that.options.slidingCookieInterval);
        },

        _getVideoPlayerToken: function () {

            console.log('Video Token: ' + this.videoPlayerToken);

            return this.videoPlayerToken;
        },

        _ifNeededBindGlobalFunctionsToWindow: function () {

            var that = this;

            //DO NOT CHANGE THE WINDOW METHODS HERE AS THEY ARE CALLED WITH THESE NAMES FROM THE SILVERLIGHT PLAYER
            if (!window.GetVideoPlayerToken) {
                var getVideoPlayerTokenHandler = $.proxy(that._getVideoPlayerToken, that);
                window.GetVideoPlayerToken = getVideoPlayerTokenHandler;
            }

            //DO NOT CHANGE THE WINDOW METHODS HERE AS THEY ARE CALLED WITH THESE NAMES FROM THE SILVERLIGHT PLAYER
            if (!window.GetCurrentUrl) {
                var getAdminPortalWebApiUrlHandler = $.proxy(that._getAdminPortalWebApiUrl, that);
                window.GetCurrentUrl = getAdminPortalWebApiUrlHandler;
            }
        },

        _startSlidingCookiesPolling: function (slidingCookieInterval) {

            var that = this;
            var getSlidingCookiesHandler = $.proxy(that._getSlidingCookies, that);

            that.timeoutId = setTimeout(getSlidingCookiesHandler, slidingCookieInterval);
        },
    });

    ui.plugin(videoPlayerManager);

})(jQuery);
﻿// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function($) {
    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget,
        LOADED = 'loaded',
        RESIZE = 'resize',
        VIDEO_PLAYER_DISPOSED = 'videoPlayerDisposed',
        VIDEO_PLAYER_CLOSED = 'videoPlayerClosed',
        VIDEO_PLAYER_USED = 'videoPlayerUsed';  // Indicates that the video player is being used by the end-user

    var videoPlayerTile = widget.extend({
        events: [
            LOADED,
            RESIZE,
            VIDEO_PLAYER_DISPOSED,
            VIDEO_PLAYER_CLOSED,
            VIDEO_PLAYER_USED
        ],

        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget).
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'VideoPlayerTile',

            // other options go here
            autoBind: true,
            loggerLevel: 'DEFAULT'
        },

        init: function(element, options) {
            var that = this;
            widget.fn.init.call(that, element, options);
            that.options.mediaApiChannelUrl = $(element).data('mediaapichannelurl');
        },

        //kendoWidgets needs to implement this function.
        value: function(dataBoundProperties) {
            var that = this;
        },

        loadSource: function(cameraEntityInformation) {
            var that = this,
                trackEvents = function(traceString) {
                    var trace;

                    try {
                        trace = JSON.parse(traceString);
                    } catch (e) {
                        trace = traceString;
                    }
                };

            var camera = new StratocastPlayer.Camera(cameraEntityInformation.cameraId, cameraEntityInformation.cameraName);

            $('.emptyTile', that.element).hide();

            $.ajax({
                type: 'POST',
                url: cameraEntityInformation.cameraAccessTokenUrl,
                success: function(data) {
                    if (that.videoPlayer) {
                        that.closeVideoPlayer();
                    }

                    var options = {
                        className: 'videoplayer',
                        logs: {
                            level: that.options.loggerLevel,
                            callback: trackEvents
                        },
                        onDispose: that.onDispose.bind(that),
                        onClose: that.onClose.bind(that),
                        onUsed: that.onUsed.bind(that)
                    };

                    if ((new Date(cameraEntityInformation.start)).getTime() > 0) {
                        $.extend(options, { start: cameraEntityInformation.start });
                    }

                    that.videoPlayer = new StratocastPlayer.VideoPlayer(that.element[0], options);
                    that.videoPlayer.connect(camera, that.options.mediaApiChannelUrl, data.Token);

                    that.camera = cameraEntityInformation;

                    that.trigger(LOADED, {
                        cameraId: that.camera.cameraId
                    });
                },
                error: function(jqxhr, textStatus, errorThrown) {
                    that.onClose();
                    //todo show error msg?
                },
                dataType: 'text json'
            });
        },

        closeVideoPlayer: function() {
            var that = this;

            if (that.videoPlayer) {
                that.videoPlayer.dispose();
                that.videoPlayer = null;
            }
        },

        hasActiveVideoPlayer: function() {
            return this.videoPlayer && this.videoPlayer.isActive;
        },

        isStreamingCamera: function(cameraId) {
            return this.hasActiveVideoPlayer() && this.camera.cameraId === cameraId;
        },

        onDispose: function() {
            var that = this;
            // Show the empty video tile placeholder
            $('.emptyTile', that.element).show();

            // Empty the video tiles from the video player DOM.
            if (that.videoPlayer) {
                $(that.videoPlayer.element).remove();

                that.trigger(VIDEO_PLAYER_DISPOSED, {
                    cameraId: that.camera.cameraId
                });

                // Remove video player from the context
                that.videoPlayer = null;
                this.camera = null;
            }
        },

        onUsed: function() {
            this.trigger(VIDEO_PLAYER_USED);
        },

        onClose: function() {
            this.trigger(VIDEO_PLAYER_CLOSED);
        },

        resetLayout: function() {
            var that = this;
            if (that.hasActiveVideoPlayer()) {
                that.videoPlayer.resetLayout();
            }
        },

        resize: function(width, height) {
            var that = this;
            if (that.hasActiveVideoPlayer()) {
                that.videoPlayer.resize(width, height);
            }
        }
    });

    ui.plugin(videoPlayerTile);
})(jQuery);

﻿// ==========================================================================
// Copyright (C) 2013 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================


(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget,
        DATABINDING = "dataBinding",
        DATABOUND = "dataBound",
        ERROR = "error",
        REQUESTEND = 'requestEnd',
        CHANGE = "change";

    var systemHealth = widget.extend({
        init: function(element, options) {
            var that = this;

            widget.fn.init.call(that, element, options);

            var dataSource = new kendo.data.DataSource({
                error: function () {
                    that.element.html(that.options.errorText);
                },
                type: "aspnetmvc-ajax",
                transport: {
                    read: {
                        url: that.options.url,
                        headers: { "g-session-skip-sliding": "true" },
                        dataType: "json"
                    }
                },
                serverSorting: true,
                serverPaging: true,
                page: 1,
                pageSize: that.options.pageSize,
                schema: {
                    data: 'Data',
                },
            });

            require([that.options.displayTemplateKey], function(displayTemplate) {
                that.template = kendo.template(displayTemplate);
                that.setDataSource(dataSource);

                if (that.options.autoRefresh && that.options.autoRefreshTimer > 0) {
                    require(["genetec/widgets/timer"], function(timer) {
                        that.setTimer(timer);
                    });
                }
            });
        },

        setTimer: function (timer) {

            var that = this;
            
            that._Timer = timer.create({
                Callback: function () {
                    that.dataSource.fetch();
                },
                Timeout: that.options.autoRefreshTimer,
                AutoRepeat: true
            });
            
        },
        
        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'SystemHealth',
            
            // other options go here
            autoBind: true,
            displayTemplateKey: 'genetec/require.template!Views_HealthDashboard_DisplayTemplates_SystemOverviewModel',
            pageSize: 5,
            autoRefresh: true,
            autoRefreshTimer: 120000,
            noDataRowText: '',
            errorText:'',
            url: '',
        },
        
        // events are used by other widgets / developers - API for other purposes
        // these events support MVVM bound items in the template. for loose coupling with MVVM.
        events: [
            // call before mutating DOM.
            // mvvm will traverse DOM, unbind any bound elements or widgets
            DATABINDING,
            // call after mutating DOM
            // traverses DOM and binds ALL THE THINGS
            DATABOUND
        ],

        // mvvm expects an array of dom elements that represent each item of the datasource.
        // should be the outermost element's children
        items: function() {
            return this.element.children();
        },
        
        setDataSource: function (dataSource) {
            // set the internal datasource equal to the one passed in by MVVM
            this.options.dataSource = dataSource;
            // rebuild the datasource if necessary, or just reassign
            this._dataSource();
        },
        
        refresh: function() {
            var that = this,
                view = that.dataSource.view(),
                html = kendo.render(that.template, view);

            // trigger the dataBinding event
            that.trigger(DATABINDING);

            // mutate the DOM (AKA build the widget UI)
            that.element.html(html);
            
            $("[data-role=grid]", that.element.html).data('roleOptions', {
                //Inject options to the Grid here
                dataBound: that._onGridDataBound,
                noRowDataText: that.options.noDataRowText,
                errorText: that.options.errorText,
            });

            //bind the widget content by uid
            $("[data-uid]", that.element).each(function () {
                kendo.bind(this, that.dataSource.getByUid($(this).data("uid")));
            });
            
            //bind the widget view to the page element
            kendo.bind(that.element, view);
            
            // trigger the dataBound event
            that.trigger(DATABOUND);
        },
        
        _onGridDataBound: function (e) {
            var grid = this;

            if (grid.dataSource.total() <= 0) {

                var columnCount = grid.columns.length;

                $(grid.tbody.get(0)).append(
                    kendo.format("<tr><td colspan='{0}'>{1}</td></tr>", columnCount, e.sender.options.noRowDataText));
            }
        },
       
        _startAutoRefresh: function () {
            if (this._Timer) {
                this._Timer.Start();
            }
        },

        _stopAutoRefresh: function () {
            if (this._Timer) {
                this._Timer.Cancel();
            }
        },
        
        _dataSource: function () {
            var that = this;

            // if the DataSource is defined and the _refreshHandler is wired up, unbind because
            // we need to rebuild the DataSource
            if (that.dataSource && that._refreshHandler) {
                that.dataSource.unbind(CHANGE, that._refreshHandler);
            }
            else {
                that._refreshHandler = $.proxy(that.refresh, that);
            }
            
            that._startRefreshHandler = $.proxy(that._startAutoRefresh, that);
            that._stopRefreshHandler = $.proxy(that._stopAutoRefresh, that);

            // returns the datasource OR creates one if using array or configuration object
            that.dataSource = kendo.data.DataSource.create(that.options.dataSource);
            // bind to the change event to refresh the widget
            that.dataSource.bind(CHANGE, that._refreshHandler);
            
            if (that.options.autoRefresh) {
                that.dataSource.bind(REQUESTEND, that._startRefreshHandler);
                that.dataSource.bind(ERROR, that._stopRefreshHandler);
            }

            if (that.options.autoBind) {
                that.dataSource.fetch();
            }
        }
    });

    ui.plugin(systemHealth);

})(jQuery);
﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget;

    var collapsiblePanel = widget.extend({
        triggerElement: null,
        contentElement: null,

        init: function (element, options) {
            var that = this;

            // base call to widget initialization
            widget.fn.init.call(this, element, options);

            this.triggerElement = this.element.find(that.options.triggerSelector);
            this.contentElement = this.element.find(that.options.contentSelector);

            if (this.contentElement.is(':visible')) {
                this.triggerElement.addClass("expanded");
            }

            this.triggerElement.on('click', function () {
                that.toggle();
            });
        },

        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: "CollapsiblePanel",
            // other options go here
            triggerSelector: '.header',
            contentSelector: '.content',
            toggle: { before: null, after: null }
        },

        toggle: function (e) {
            var that = this;
            if ($.isFunction(that.options.toggle.before)) that.options.toggle.before();

            this.triggerElement.toggleClass("expanded");

            this.contentElement.slideToggle({
                direction: "up",
                step: that.options.toggle.after,
                complete: that.options.toggle.after,
                duration: 300
            });
        }

    });

    ui.plugin(collapsiblePanel);

})(jQuery);
﻿// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        Widget = ui.Widget,
        DATABINDING = "dataBinding",
        DATABOUND = "dataBound",
        CHANGE = "change",
        FILTER = "filter",
        NAVIGATE_CLICK = 'navigateClick';

    var thumbnailViewer = Widget.extend({
        model: null,
        defaultSlideAmount: 200, // in pixels
        leftNavElement: null,
        rightNavElement: null,
        thumbnailsElement: null,

        init: function(element, options) {
            var that = this;

            // base call to widget initialization
            Widget.fn.init.call(this, element, options);

            var dataSource = new kendo.data.DataSource({
                type: "aspnetmvc-ajax",
                transport: {
                    read: {
                        url: that.options.url,
                        headers: { "g-session-skip-sliding": "true" },
                        dataType: "json"
                    }
                },
                serverSorting: true,
                serverPaging: true,
                page: 1,
                pageSize: that.options.pageSize,
                schema: {
                    data: 'Data'
                }
            });

            require([that.options.displayTemplateKey, that.options.displayThumbnailTemplateKey],
                function(displayMainTemplate, displayThumbnailTemplate) {
                    that.element.html(kendo.template(displayMainTemplate));
                    that.thumbnailTemplate = kendo.template(displayThumbnailTemplate);

                    that.setDataSource(dataSource);

                    that.leftNavElement = that.element.find(".thumbnailsNavigation.left");
                    that.rightNavElement = that.element.find(".thumbnailsNavigation.right");
                    that.thumbnailsElement = that.element.find(".thumbnails");

                    that._setupModel();

                    $(window).resize($.proxy(that._resized, that));
                    $(window).resize();
                });

        },

        destroy: function() {
            $(window).off("resize", this._resized);
        },

        _resized: function() {
            this.defaultSlideAmount = this._getContentRelativeSlidingAmount(0.7);

            this.model.set("thumbnailsTotalWidth", this._getThumbnailsTotalWidth() || this.thumbnailsElement.outerWidth(true));
            this.updateNavigationState();

            var rightSpaceThatCanBefilled = this._getRightHiddenWidth() * -1;
            if (rightSpaceThatCanBefilled > 0) {
                this.navigateLeft(rightSpaceThatCanBefilled);
            }
        },

        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget).
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: "ThumbnailViewer",
            // other options go here
            autoBind: true,
            filterField: "Name",
            filterPlaceHolder: "",
            displayTemplateKey: 'genetec/require.template!Views_Shared_DisplayTemplates_ThumbnailViewer',
            displayThumbnailTemplateKey: 'genetec/require.template!Views_Shared_Thumbnail',
            pageSize: 100,
            url: ''
        },

        filter: function(filterText) {
            var that = this;
            that.dataSource.filter({ field: that.options.filterField, operator: "contains", value: filterText });
            that.trigger(FILTER);
        },

        refresh: function() {
            var that = this,
                view = that.dataSource.view(),
                html = kendo.render(that.thumbnailTemplate, view);

            that.trigger(DATABINDING);

            kendo.bind($(that.element), that.model);

            that.element.find(".slider").html(html);
            this._resized();

            that.trigger(DATABOUND);
        },

        setDataSource: function(dataSource) {
            // set the internal datasource equal to the one passed in by MVVM
            this.options.dataSource = dataSource;
            // rebuild the datasource if necessary, or just reassign
            this._dataSource();
        },

        _dataSource: function() {
            var that = this;

            // if the DataSource is defined and the _refreshHandler is wired up, unbind because
            // we need to rebuild the DataSource
            if (that.dataSource && that._refreshHandler) {
                that.dataSource.unbind(CHANGE, that._refreshHandler);
            } else {
                that._refreshHandler = $.proxy(that.refresh, that);
            }

            // returns the datasource OR creates one if using array or configuration object
            that.dataSource = kendo.data.DataSource.create(that.options.dataSource);
            // bind to the change event to refresh the widget
            that.dataSource.bind(CHANGE, that._refreshHandler);

            if (that.options.autoBind) {
                that.dataSource.fetch();
            }
        },

        // events are used by other widgets / developers - API for other purposes
        // these events support MVVM bound items in the template. for loose coupling with MVVM.
        events: [
            // call before mutating DOM.
            // mvvm will traverse DOM, unbind any bound elements or widgets
            DATABINDING,
            // call after mutating DOM
            // traverses DOM and binds ALL THE THINGS
            DATABOUND,
            FILTER,
            NAVIGATE_CLICK
        ],

        _setupModel: function() {
            var that = this;
            that.model = kendo.observable({
                disableLeftNav: false,
                disableRightNav: false,
                sliderLeftCoordinate: 0,
                sliderLeftPosition: function() {
                    return this.get("sliderLeftCoordinate") + "px";
                },
                thumbnailsTotalWidth: 10000,
                sliderWidth: function() {
                    return this.get("thumbnailsTotalWidth") + "px";
                },
                navigateLeft: function() {
                    that.navigateLeft(that.defaultSlideAmount);
                },
                navigateRight: function() {
                    that.navigateRight(that.defaultSlideAmount);
                },
                filter: function() {
                    var filterText = $("#searchText").val();
                    that.filter(filterText);

                    $("#searchText").focus();
                },
                searchValue: "",
                filterPlaceHolder: that.options.filterPlaceHolder
            });

            var timer;

            that.model.bind("set",
                function(e) {
                    if (e.field === "disableLeftNav") {
                        that.leftNavElement.toggleClass("disabled", e.value);
                    }
                    if (e.field === "disableRightNav") {
                        that.rightNavElement.toggleClass("disabled", e.value);
                    }
                    if (e.field === "searchValue") {
                        if (timer){
                            clearTimeout(timer);
                        }
                        timer = setTimeout(function(){
                            that.filter(e.value);
                        }, 500);
                    }
                });
        },

        navigateLeft: function(wantedSlideAmount) {
            if (this.model.get("disableLeftNav")) return;

            var remainingLeft = this._getLeftHiddenWidth();
            var slideAmount = Array.min([remainingLeft, wantedSlideAmount]);

            this.model.set("disableLeftNav", remainingLeft <= this.defaultSlideAmount);
            this.model.set("disableRightNav", remainingLeft <= 0 || this._getRightHiddenWidth() + slideAmount <= 0);
            this.model.set("sliderLeftCoordinate", this.model.get("sliderLeftCoordinate") + slideAmount);

            this.trigger(NAVIGATE_CLICK);
        },

        navigateRight: function(wantedSlideAmount) {
            if (this.model.get("disableRightNav")) return;

            var remainingRight = this._getRightHiddenWidth();
            var slideAmount = Array.min([remainingRight, wantedSlideAmount]);

            this.model.set("disableLeftNav", remainingRight <= 0 || this._getLeftHiddenWidth() + slideAmount <= 0);
            this.model.set("disableRightNav", remainingRight <= this.defaultSlideAmount);
            this.model.set("sliderLeftCoordinate", this.model.get("sliderLeftCoordinate") - slideAmount);

            this.trigger(NAVIGATE_CLICK);
        },

        updateNavigationState: function() {
            this.model.set("disableLeftNav", this._getLeftHiddenWidth() <= 0);
            this.model.set("disableRightNav", this._getRightHiddenWidth() <= 0);
        },

        _getRightHiddenWidth: function() {
            return (this.model.get("sliderLeftCoordinate") + this.model.get("thumbnailsTotalWidth")) -
                (this.thumbnailsElement.outerWidth(true));
        },

        _getLeftHiddenWidth: function() {
            return (this.model.get("sliderLeftCoordinate") * -1);
        },

        _getContentRelativeSlidingAmount: function(proportion) {
            return Math.round(this.thumbnailsElement.outerWidth(true) * proportion);
        },

        _getThumbnailsTotalWidth: function() {
            var sum = 0;
            this.element.find(".slider .thumbnail").each(function() { sum += $(this).outerWidth(true); });
            return Math.round(sum);
        }
    });

    ui.plugin(thumbnailViewer);

})(jQuery);